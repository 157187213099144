@import '~bootstrap/scss/bootstrap';
@import './StyleConstants.scss';

/**Fonts*/
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url(./assets/Fonts/Inter/static/Inter-Thin.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url(./assets/Fonts/Inter/static/Inter-Light.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url(./assets/Fonts/Inter/static/Inter-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url(./assets/Fonts/Inter/static/Inter-Medium.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url(./assets/Fonts/Inter/static/Inter-SemiBold.ttf) format("truetype");
  font-display: swap;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

body {
  margin: 0;
  padding: 0;
  font-size: 12px !important;
  line-height: normal !important;
  font-weight: 400 !important;
  font-family: Inter !important;
  color: #ffffff;
}


.mei-dark {
  .bg-dark-grey {
    background-color: $dark-grey;
  }

  .bg-dark-grey-less-opacity {
    background-color: $dark-grey-less-opacity
  }

  .bg-dark-grey-medium-opacity {
    background-color: $dark-grey-medium-opacity
  }

  .bg-light-grey {
    background-color: $light-grey;
  }

  .bg-mild-grey {
    background-color: $mild-grey;
  }

  .bg-mild-grey-hover:hover {
    background-color: $mild-grey;
  }

  .font-color-white {
    color: $font-color-white;
  }

  .font-color-extra-light-grey {
    color: $font-color-extra-light-grey;
  }

  .accordion {
    --bs-accordion-color: $font-color-white;
    --bs-accordion-bg: $dark-grey;
    --bs-accordion-border-width: 0;
    --bs-accordion-btn-color: $font-color-white;
    --bs-accordion-btn-focus-border-color: $dark-grey;
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-active-color: $font-color-white;
    --bs-accordion-active-bg: $dark-grey;
    --bs-accordion-border-radius: 6px;
    --bs-accordion-btn-icon: unset;
    --bs-accordion-btn-active-icon: unset;
  }
}

// No themed screens start
.font-color-light-grey {
  color: #929292;
}

.font-color-grey {
  color: #333;
}

.font-color-completed-status {
  color: #019E86;
}

.font-color-progress-status {
  color: #0F82C3;
}

.font-color-failure-status {
  color: red;
}

// No themed screens end

.font-thin {
  font-size: 10px;
  line-height: 14px;
}

.font-exsm {
  font-size: 12px;
  line-height: 16px;
}

body {
  font-size: 14px !important;
}

.font-body {
  font-size: 14px;
}

.font-sm {
  font-size: 18px;
  line-height: 22px;
}

.font-md {
  font-size: 24px;
  line-height: 28px;
}

.font-lg {
  font-size: 40px;
  line-height: 44px;
}

.font-xl {
  font-size: 46px;
  line-height: 50px;
}

@media (min-width:991.98px) {
  .font-thin {
    font-size: 12px;
    line-height: 18px;
  }

  .font-exsm {
    font-size: 14px;
    line-height: 20px;
  }

  body {
    font-size: 16px !important;
  }

  .font-body {
    font-size: 16px;
  }

  .font-sm {
    font-size: 20px;
    line-height: 26px;
  }

  .font-md {
    font-size: 26px;
    font-style: 32px;
  }

  .font-lg {
    font-size: 42px;
    line-height: 48px;
  }

  .font-xl {
    font-size: 46px;
    line-height: 52px;
  }

}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 12px;
}

@media (max-width:400px) {
  .mei-dark {
    .bg-dark-grey {
      background-color:$bg-lightgrey !important;
    }
  
    .bg-dark-grey-less-opacity {
      background-color: $dark-grey-less-opacity
    }
  
    .bg-dark-grey-medium-opacity {
      background-color: $dark-grey-medium-opacity
    }
  
    .bg-light-grey {
      background-color: $font-color-white;
    }
  
    .bg-mild-grey {
      background-color: $mild-grey;
    }
  
    .bg-mild-grey-hover:hover {
      background-color: $mild-grey;
    }
  
    .font-color-white {
      color: $font-color-white;
    }
  
    .font-color-extra-light-grey {
      color:$text-color-black;
    }
  
    .accordion {
      --bs-accordion-color: $font-color-white;
      --bs-accordion-bg: $dark-grey;
      --bs-accordion-border-width: 0;
      --bs-accordion-btn-color: $font-color-white;
      --bs-accordion-btn-focus-border-color: $dark-grey;
      --bs-accordion-btn-focus-box-shadow: none;
      --bs-accordion-active-color: $font-color-white;
      --bs-accordion-active-bg: $dark-grey;
      --bs-accordion-border-radius: 6px;
      --bs-accordion-btn-icon: unset;
      --bs-accordion-btn-active-icon: unset;
    }
  }
}