.mei-logo-spinner-wrapper {
    position: fixed;
    z-index: 1300;
    inset: 0px;
}

.loader-overlay {
    justify-content: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
    position: fixed;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
}

.mei-logo-spinner-container {
    background-color: rgba(0, 0, 0, 0.32);
}

.mei-logo-spinner {
    animation: bubble 1200ms cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
    width: 44px;
    height: auto;
    object-fit: contain;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes bubble {
    0% {
        transform: translate3d(0, 0, 0) rotate(0);
    }

    100% {
        transform: translate3d(0, 0, 0) rotate(360deg);
    }
}