.like-share-options-container {
    gap: 8px;
}

.like-share-options {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 50%;
    padding: 6px;
}

.like-shareHistory-options {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 6px;
}

.continue-blur {
    filter: opacity(0.5);
}