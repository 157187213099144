@import '../../StyleConstants.scss';

.chat-window-wrapper {
    position: relative;
    border-radius: 36px;
    margin: 70px 12px 16px 12px;
    height: 100%;
    min-height: calc(100vh - 86px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.dummy-content {
    flex: auto;
    transition: flex 0.8s ease-in-out;
}

.chat-screen .dummy-content {
    flex: 0;
}

.chat-window-wrapper::before {
    background: url('/assets/ChatHistory/backgroundTheme1.gif') no-repeat center center;
    background-size: cover;
    content: '';
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    transition: all 0.8s ease-in-out;
    border-radius: 36px;
    opacity: 0.6;
}

.background-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1;
    border-radius: 36px;
}

/* Make content visible over video */
.chat-window-container,
.chat-screen-contents-box,
.back-button-wrapper,
.dummy-content {
    position: relative;
    z-index: 2;
}

.chat-window-container {
    position: relative;
    top: 0;
    padding: 36px 36px 0px 36px;
    transition: top 0.8s ease-in-out;
    z-index: 1;
    height: max-content;
}

.chat-screen .chat-window-container {
    padding: 0px 8px;
}

.suggestions-cards-wrapper {
    margin-bottom: 14px;
    transition: all 0.8s 0.8s ease-in-out;
}

.suggestion-icons {
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 50%;
}

.suggestions-card-container {
    border-radius: 6px;
    padding: 18px 20px;
    gap: 12px;
    backdrop-filter: blur(14px);
    height: 100%;
}

.suggestion-description {
    margin: 0;
    font-weight: 500;
}

.search-query-button {
    background-image: url('/assets/ChatHistory/querySearchArrowDark.svg');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    width: 34px;
    height: 34px;
    background-position: center center;
    border: none;
    border-radius: 6px;
    right: 12px;

}

.cursorshow {
    cursor: pointer;
}

.query-input-field {
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.17);
    backdrop-filter: blur(14px);
    height: 56px;
    padding: 12px 58px 12px 12px;
    border: none;
    color: $font-color-white;
    caret-color: $font-color-white;
}

.chat-input-field-wrapper {
    border-radius: 12px;
}

.query-input-field:focus {
    outline: none;
}

.discover-description {
    margin-top: 28px;
    margin-bottom: 26px;
}

.chat-screen .chat-window-container {
    padding-bottom: 0px;
    // top: 0%;
}

.chat-screen .suggestions-cards-wrapper,
.chat-screen .discover-description {
    transition: opacity 0.8s ease-in-out, margin 1.2s ease-in-out, max-height 1.2s ease-in-out;
    opacity: 0;
    overflow: hidden;
    margin-top: 0;
}

.chat-screen::before {
    opacity: 0;
    z-index: -1;
}

.tell-me-more-wrapper {
    padding: 12px 16px 12px 40px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 500;
}

.chat-screen .back-button-wrapper {
    visibility: visible;
    opacity: 1;
    pointer-events: unset;
    display: flex;
}

.back-button-wrapper {
    font-weight: 500;
    margin: 20px 0px 0px 44px;
    padding-left: 20px;
    cursor: pointer;
    background-color: transparent;
    border: unset;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
    ;
}

.back-button-wrapper::before {
    content: "";
    background: url('/assets/ChatHistory/back.svg') no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0px;
    background-position: center center;
}

@keyframes reloadSpin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.tell-me-more-wrapper::before {
    position: absolute;
    content: '';
    background-image: url('/assets/ChatHistory/reload.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
    left: 16px;
    top: 11px;
}

.reload-button {
    margin-left: 20px;
    border: none;
    border-radius: 12px;
}

.regenerate-disable {
    opacity: 1;
    /* 0.3 */
    /* filter: blur(1px); */
}

.whitespace {
    white-space: pre-line;
}

.reload-button::before {
    content: '';
    display: inline-block;
    background-image: url('/assets/ChatHistory/reload.svg');
    background-repeat: no-repeat;
    background-size: 26px 26px;
    background-position: center center;
    width: 56px;
    height: 56px;
}


.reload-button::before.reload-active {
    animation: reloadSpin 1.6s infinite linear;
}

.reload-active::before {
    animation: reloadSpin 1.6s infinite linear;
}

.user-avatar {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
}


.user-query-wrapper {
    margin-bottom: 26px;
    gap: 16px;
}

.user-query {
    padding: 4px 8px;
    margin: 0;
    border-radius: 6px;
}

.user-query-container {
    padding: 0px 16px;
}

.like-options-box {
    margin-top: 18px;
    margin-bottom: 32px;
    gap: 16px;
}

.user-answer-wrapper {
    gap: 16px;
    padding-bottom: 12px;
}

.user-answer-wrapper p {
    margin-bottom: 0;
}

@keyframes initialChatLoad {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

.chat-screen-contents {
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
}

.tell-me-more-box {
    padding: 12px 0px;
}

.chat-screen-contents-box {
    padding: 20px 0px;
    // display: none;
}

.history-chat {
    max-height: calc(100vh - 126px);
}

.live-chat {
    max-height: calc(100vh - 268px);
}

.chat-screen .chat-screen-contents {
    animation: initialChatLoad 0.8s 1s forwards ease-in-out;
}

.dot-shuttle-contianer {
    width: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot-shuttle {
    width: 6px;
    aspect-ratio: 1;
    border-radius: 50%;
    color: $font-color-extra-light-grey;
    animation:
        dot-shuttle-one .75s infinite linear alternate,
        dot-shuttle-two 1.5s infinite linear;
}

@keyframes dot-shuttle-one {

    0%,
    20% {
        box-shadow: 30px 0 0 1px, 10px 0 0 1px, -10px 0 0 1px, -30px 0 0 1px
    }

    60%,
    100% {
        box-shadow: 12px 0 0 1px, 14px 0 0 3px, -14px 0 0 3px, -12px 0 0 1px
    }
}

@keyframes dot-shuttle-two {

    0%,
    25% {
        transform: rotate(0)
    }

    50%,
    100% {
        transform: rotate(.5turn)
    }
}

@media only screen and (min-width:991.98px) {
    .chat-window-wrapper {
        height: calc(100vh - 86px);
    }

    .chat-screen .chat-window-container {
        padding: 0px 36px;
    }

    .user-query-container {
        padding: 0px 8%;
    }

    // .chat-window-container {
    //     top: 40%;
    // }
}

@media only screen and (min-width:1199.98px) {
    .chat-window-wrapper {
        margin: 16px 12px;
        min-height: calc(100vh - 32px)
    }

    .history-chat {
        max-height: calc(100vh - 86px);
    }

    .live-chat {
        max-height: calc(100vh - 228px);
    }
}

@media only screen and (max-width:400px) and (orientation:portrait) {

    /* Portrait styles */
    #discover-descriptiondata {
        display: none !important;
    }

    .chat-window-wrapper::before {
        background: url('/assets/ChatHistory/wizback3.jpg') no-repeat center center;
        ;
        background-size: cover;
        content: '';
        display: inline-block;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        height: 100%;
        transition: all 0.8s ease-in-out;
        border-radius: 36px;
    }

    .search-query-button {
        background-image: url('/assets/ChatHistory/white_chatsend.svg');
    }

    .query-input-field {
        color: $dark-grey;
        caret-color: $dark-grey;
    }

    .tell-me-more-wrapper::before {
        background-image: url('/assets/ChatHistory/light_gene_btn.svg');
    }
}

.chat-screen-contents-box {
    .meii-text-message {
        background-color: #161719;
        border-radius: 10px;
        padding: 4px 8px;
        margin-bottom: 10px;
    }
}

.chat-list-box {
    background-color: #161719;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 10px;
}

.chat-list-box {
    .chat-list-label {
        margin-bottom: 0px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #BEC1C8;
    }
}

.chat-list-box {
    button {
        white-space: nowrap;
        color: #4c8bea;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        border: 0;
        padding: 0;
        height: 20px;
    }
}

.chat-list-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 35px;
}

.chat-list-box {
    .chat-list-container {
        p {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.chat-list-container:not(:last-child),
.organization-activity-container:not(:last-child) {
    border-bottom: 0.5px solid #cccccc;
}

.chat-list-box {
    button {
        white-space: nowrap;
        color: #4c8bea;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        border: 0;
        padding: 0;
        height: 20px;
    }
}

.receiver-box {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    max-width: 100%;
    height: fit-content;
}

.sender-box p,
.receiver-box .chat-answer {
    border-radius: 10px;
    margin: 0;
    width: 100%;
    overflow-wrap: break-word;
    white-space: normal;
}

.receiver-box.view-size-medium {
    max-width: 80%;
}

.chat-list-suggestion-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: start;
}

.suggestions-chip-wrapper-box,
.org-quick-btns {
    margin-top: 0px;
    margin-bottom: 0px;
}

.suggestions-chip-wrapper-box {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 12px;
}

.suggestion-chip-container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
}

.suggestion-chips {
    color: #ffffff;
    background-color: transparent;
    border-radius: 24px;
    border: 1px solid #0063f6;
    padding: 10px 12px;
    align-self: flex-end;
    cursor: pointer;
    height: 40px;
    font-size: 14px;
}

.org-quick-btns {
    display: inline-flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 12px;
    margin-bottom: 2px;
}

.suggestions-chip-wrapper-box,
.org-quick-btns {
    margin-top: 0px;
    margin-bottom: 0px;
}

.icon-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.chat-list-container button {
    font-size: 14px;
}

.icon-img {
    width: 22px;
}

.redirect-link {
    text-decoration: none;
}

.chat-org-container {
    h1 {
        font-size: 18px;
    }

    p {
        font-size: 14px;
    }

    .section {
        margin-top: 10px;

        .section-title {
            font-size: 14px;
        }

        .section-content {
            font-size: 14px;
        }
    }
}

.user-answer-wrapper {
    .chat-list-box {
        h6 {
            margin-bottom: 0px;
        }
    }
}

.chart-wrapper-box {
    width: 100%;
}

.chart-wrapper-box {
    .recharts-responsive-container {
        max-height: 250px;
        min-height: 320px;
    }
}