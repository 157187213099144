@import '../../StyleConstants.scss';

.feedback-wrapper {
    border-radius: 18px;
    padding: 12px;
    margin: 18px 0px 12px 0px;
}

.empathy-statement {
    margin-bottom: 24px;
    font-weight: 500;
}

.cross {
    padding: 6px;
    cursor: pointer;
    background: url('/assets/ChatHistory/cross.svg') no-repeat center center;
    width: 24px;
    height: 24px;
    background-size: 10px 12px;
    border: none;
    border-radius: 16px;
}

.feedback-chips {
    border: none;
    cursor: pointer;
    padding: 6px;
    border-radius: 8px;
}

.feedback-description-field {
    border: none;
    caret-color: $font-color-extra-light-grey;
    padding: 12px 16px;
    width: 100%;
    margin: 12px 0px;
    border-radius: 12px;
    min-height: 72px;
}

.feedback-description-field:focus {
    border: none;
    outline: none;
}

.feedback-submit-button {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    border-radius: 32px;
    font-weight: 500;
}