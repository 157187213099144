.chat-genertor-layout-container {
    min-height: 100vh;
}

.mobile-sidebar-wrapper {
    display: flex;
    position: fixed;
    width: 100vw;
    height: calc(100vh - 60px);
    top: 60px;
    z-index: 12;
    transition: all .3s;
    box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.5);
    text-align: left;
    overflow-y: hidden;
}

.mobile-histroy-sidebar {
    left: -100vw
}

.mobile-capabilities-sidebar {
    right: -100vw;
}

.mobile-histroy-sidebar.active {
    left: 0;
}

.mobile-capabilities-sidebar.active {
    right: 0;
}

@media only screen and (max-wdith:1199.98px) {
    .overlay {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(51, 51, 51, 0.7);
        z-index: 10;
        opacity: 0;
        top: 0;
        left: 0;
        transition: all .5s ease-in-out;
    }

    .overlay.active {
        display: block;
        opacity: 1;
    }
}

@media only screen and (min-width:767.98px) {
    .mobile-sidebar-wrapper {
        width: 40vw;
        max-width: 400px;
    }

    .mobile-histroy-sidebar {
        left: -40vw
    }

    .mobile-capabilities-sidebar {
        right: -40vw;
    }
}

@media only screen and (min-width:1199.98px) {
    .mobile-sidebar-wrapper {
        position: static;
        flex: 0 0 auto;
        width: 25%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        height: 100%;
        min-height: 100vh;
        box-shadow: none;
    }
}

@media only screen and (min-width:1599.98px) {
    .mobile-sidebar-wrapper {
        width: 20%;
    }
}