.domain-input-fields-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 10;
    padding: 20px;
}

.main-logo {
    height: 26px;
    width: auto;
    object-fit: contain;
    margin-left: 20px;
}

.free-trail-label {
    color: #333333;
    margin: 16px 26px 22px 20px;
}

.error-message {
    margin: 8px 12px 2px 20px;
    color: #f44336;
    height: 15px;
}

.email-field-container {
    margin-bottom: 16px;
}

.domain-field {
    border-radius: 18px;
    border: 1px solid #F0F0F0;
    outline: none;
    background: #F0F0F0;
    padding: 12px 20px;
    height: 54px;
    width: calc(100vw - 40px);
    color: #333333;
    font-weight: 400;
    caret-color: #333333
}

.domain-field:focus {
    outline: none
}

.domain-field::placeholder {
    color: #A9A9A9;
    font-weight: 400;
    line-height: normal;
}

.domain-field-container {
    position: relative;
}


.recapta-container,
.recaptcha-wrapper {
    width: max-content;
}

.recaptcha-border {
    background-color: #f9f9f9;
}

.left-border {
    height: 78px;
    width: 2px;
    left: 0;
}

.top-border {
    width: 304px;
    height: 2px;
    top: 0;
}

.bottom-border {
    width: 304px;
    height: 2px;
    bottom: 2px;
}

.right-border {
    height: 78px;
    width: 2px;
    right: 2px;
}

.continue-button {
    border-radius: 12px;
    background: #333;
    color: #fff;
    padding: 12px;
    width: 100%;
    border: none;
    font-weight: 500;
}

.errorMsg {
    margin-top: 10px !important;
    max-width: 400px;
}

.continue-blur {
    filter: opacity(0.5);
}

.pointer {
    cursor: pointer;
}

.no-cursor {
    cursor: not-allowed;
}

.recaptcha-wrapper {
    margin: 12px;
}

.magic-link-contents {
    max-width: 740px;
    padding: 20px;
}

@media (min-width: 575.98px) {
    .magic-link-contents {
        max-width: 740px;
        padding: 40px;
    }

    .domain-field {
        width: 400px;
    }

    .recaptcha-wrapper {
        margin: 20px 36px 32px 20px;
    }

}