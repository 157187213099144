.capabilities-try-links-container {
    gap: 12px;
    padding-top: 20px;
    // overflow-y: auto;

    .slick-list,
    .slick-slider {
        height: 100% !important;
    }
}

.capabilities-wrapper {
    // padding: 0px 0px 40px 0px;
    // padding: 40px 20px;
    border-radius: 36px;
    overflow-y: hidden;
}

.capabilities-wrapper p {
    color: #8C8C8C;
    padding: 20px;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 0;
    overflow-y: auto;
}

.capabilities-img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
}

.capabilities-img,
.capabilities-img-container {
    flex: 1;
    min-height: 200px;
}

.capabilities-img-container::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 12px;
    width: 100%;
    height: 60px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(3px);
    z-index: 20;
}


.capabilities-field-contents p::after {
    position: absolute;
    content: '';
    left: 0;
    top: -48px;
    width: 100%;
    height: 54px;
    background: rgba(255, 255, 255, 0.01);
    filter: blur(11.5px);
    backdrop-filter: blur(10px);
}

.capabilities-title {
    margin-bottom: 20px;
    font-weight: 500;
}

.capabilities {
    margin-bottom: 16px;
}

.capabilities,
.accordion-button {
    border-radius: 6px;
}

.capabilities-container {
    // height: 50vh;
    // display: flex;
    // flex-direction: column;
    // gap: 16px;
    padding-bottom: 40px;
    overflow-y: hidden;
}

.try-links-wrapper {
    padding: 20px;
    border-radius: 36px;
}

.try-title {
    margin-bottom: 12px;
    font-weight: 500;
}

.try-links-chips {
    padding: 8px 26px 8px 10px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    color: #7B7B7B;
    text-decoration: none;
}

.try-links-chips::after {
    position: absolute;
    content: '';
    background-image: url('/assets/ChatHistory/domainArrowsGrey.svg');
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    background-size: contain;
    margin-left: 8px;
    top: 11px;
}

.try-link-chips-wrapper {
    gap: 8px;
}

.social-icons {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 40px;
    border: 1px solid #484848;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background: #878787;
    }

    &.instagram {
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("/assets/ChatHistory/instagramGrey.svg");

        &:hover {
            background-image: url("/assets/ChatHistory/instagramBlack.svg");
        }
    }

    &.twitter {
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("/assets/ChatHistory/twitterGrey.svg");

        &:hover {
            background-image: url("/assets/ChatHistory/twitterBlack.svg");
        }
    }

    &.linkedin {
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("/assets/ChatHistory/linkedinGrey.svg");

        &:hover {
            background-image: url("/assets/ChatHistory/linkedinBlack.svg");
        }
    }
}
.contactus{
    .recapta-container,
.recaptcha-wrapper {
    width: max-content;
}
.rc-anchor-light {

}
.recaptcha-border {
    background-color: #f9f9f9;
}
    .error-message {
        margin: 8px 0px 0px 0px;
        color: #f44336;
        height: 15px;
    }
    .ant-modal-content{
        background: #252628 !important;
    }
    .ant-modal-title{
        font-size: 20px;
        color: #BEC1C8;
        background: #252628;
    }
    .description{
        font-size:16px ;
        color: #BEC1C8;

    }
    .react-tel-input .form-control {
        color: #BEC1C8 !important;
        height: 54px !important;
        width: 95% !important;
        border-radius: 18px !important;
      }
      .contactus_submit{
        color: #BEC1C8;
        padding: 10px 35px;
        display: block;
        margin: auto;
        border-radius: 5px;
      }
      .input{
        background: #252628 !important;
        border: 1px solid #333 !important;
        color: #BEC1C8 !important;
      }
      .react-tel-input .selected-flag{
        background: #252628 !important;
        border: 1px solid #333 !important;
        border-radius: 18px 0px 0px 18px !important;
      }
      .react-tel-input .flag-dropdown {
        border: 1px solid #333 !important;
        border-radius: 18px 0px 0px 18px !important;
        background: #252628 !important;

      }
      .rc-anchor-light{
        background: #252628 !important;
      }
      .ant-modal-close-x{
        color: #BEC1C8 !important;

      }
      input[type="checkbox"] {
        accent-color:#333;
        }
}
@media (min-width:1199.98px) {
    .capabilities-try-links-container {
        overflow-y: auto;
        padding: 26px 12px;
    }
}

@media (max-width:1199.98px) {
    .capabilities-wrapper {
        height: 100% !important;
    }
}