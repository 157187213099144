.cursor-pointer {
    cursor: pointer;
}

.header-wrapper {
    position: relative;
    z-index: 14;
    width: 100%;
}
.color-icon{
    display: none;
}

@media (min-width:1199.98px) {
    .header-wrapper {
        width: 100%;
    }

}



@media only screen and (max-width:400px) and (orientation:portrait) {
    /* Portrait styles */
    img#hamburger {
        visibility: hidden !important;
      }
    // img#meilogohide{
    //     visibility: hidden !important;   
    // }  
    .menu-icon-dark{
        display: none;
    }
    .menu-icon{
        height: 35px;
    }
    .color-icon{
        display: block;
    }
}