@import '../../StyleConstants.scss';

.chat-history-wrapper {
    padding: 26px 12px;
    width: calc(100% - 68px);
    overflow-y: auto;
    // width: calc(100vw - 68px);

    .mei-logo {
        height: 18px;
        width: auto;
        object-fit: contain;
        margin-bottom: 32px;
    }

    .mentions-description {
        word-break: break-word;
    }

    .parent-org,
    .parent-org a {
        margin-bottom: 0;
        font-size: 12px;
        margin-top: 8px;
        text-decoration: none;
    }

    .parent-org a {
        &:hover {
            text-decoration: underline;
        }
    }

    .chat-history-anchor {
        text-decoration: none;
        color: #BEC1C8;
    }

    .chat-search-field-wrapper {
        display: flex;
        gap: 8px;
        margin-bottom: 24px;
    }

    .chat-search-field {
        border-radius: 12px;
        padding: 10px;
        height: 36px;
    }


    .chat-search-field:focus {
        outline: none;
    }

    .search-button-wrapper {
        background-repeat: no-repeat;
        background-position: center;
        border-color: transparent;
        border-radius: 12px;
        width: 44px;
        height: 36px;
        cursor: pointer;
    }

    .keyword-count {
        font-weight: 500;
    }

    .chat-history-date {
        opacity: 0.6;
        margin-bottom: 6px;
        margin-left: 10px;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        position: relative;
        padding: 16px 16px 16px 50px;
        border-radius: 12px;
        margin-bottom: 4px;
        cursor: pointer;
    }

    li p {
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
        margin-bottom: 0px;
    }

    li::before {
        position: absolute;
        content: url("/assets/ChatHistory/chatDarkTheme.svg");
        left: 12px;
    }

    .keyword-mentions-wrapper {
        border-radius: 36px;
        padding: 12px 24px;
    }

    .keyword-mentions-wrapper {
        transition: all 1.4s ease-in-out;
        transform: scale(0.85);
        transform: scale(1);
    }

    .next-slide-anim .keyword-mentions-wrapper {
        transform: scale(1);
    }

    .mention-label {
        margin: 12px 0px 28px 0px;
    }

    .chat-history-data-container {
        min-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .new-chat {
        padding: 12px;
        border: none;
        cursor: pointer;
        border-radius: 12px;
        margin-bottom: 12px;
        font-weight: 500;
    }
}

.mei-dark {
    .chat-search-field {
        border: 1px solid $light-grey;
        caret-color: $light-grey;
    }

    .search-button-wrapper {
        background-image: url("/assets/global/search.svg");
    }

    .chat-search-field:focus {
        border: 1px solid $light-grey;
    }

    .keyword-mentions-wrapper {
        border-left: 1px solid $light-grey;
    }

    .new-chat::before {
        content: url("/assets/ChatHistory/add.svg");
        margin-right: 8px;
        position: relative;
        top: 1px
    }

    .continue-blur {
        filter: opacity(0.5);
        cursor: not-allowed;
    }
}