.progress-contents-wrapper {
    position: relative;
    z-index: 10;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    height: 100%;

    .progress-contents-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .progress-wrapper-box {
        height: 70vh;
        min-height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .progress-title {
        font-weight: 600;
        margin-bottom: 34px;
    }

    .progress-wrapper ol {
        padding-left: 0;
    }

    .pending-icon {
        border: 2px solid #E9E9E9;
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }

    .progress-bar-icon-container {
        height: 100%;
    }

    .progress-bar-container {
        width: 2px;
        height: 50px;
        border-radius: 26px;
        background: #E9E9E9;
        margin: 6px 0px;
    }

    .stepper-label {
        font-weight: 500;
    }

    .stepper-label-status-container {
        margin: 3px 0px 0px 12px;
    }

    .progress-stepper-label {
        margin: 4px 16px 0px 0px;
        white-space: nowrap;
    }

    .stepper-status {
        text-transform: capitalize;
        font-weight: 500;
    }

    .completed-status {
        background-color: rgba(3, 194, 164, 0.21);
        padding: 6px;
        border-radius: 50%;
        height: 22px;
        width: 22px;
    }

    @keyframes ripple {
        0% {
            box-shadow: 0 0 0 0 rgba(45, 173, 244, 0.29)
        }

        100% {
            box-shadow: 0 0 0 16px rgba(45, 173, 244, 0)
        }
    }

    .in-progress-icon {
        position: relative;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: rgba(45, 173, 244, 0.29);
        display: flex;
        align-items: center;
        justify-content: center;
        animation: ripple 2s linear infinite;
    }

    .in-progress-icon::after {
        position: absolute;
        content: '';
        width: 6px;
        height: 6px;
        background-color: #2DADF4;
        border-radius: 50%;
    }

    .failed-icon {
        position: relative;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: rgba(255, 0, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        animation: ripple 2s linear infinite;
    }

    .failed-icon::after {
        position: absolute;
        content: '';
        width: 6px;
        height: 6px;
        background-color: red;
        border-radius: 50%;
    }

    .contents-container {
        overflow-y: auto;
        padding-bottom: 20px;
        width: 100%;
        margin-right: 12px;
    }

    .contents-container h2 {
        margin-bottom: 12px;
        font-weight: 600;
    }

    .contents-container h2.leadership-title {
        margin-bottom: 12px;
        font-weight: 500;
    }

    .contents-container p {
        font-weight: 500;
    }



    .slick-dots {
        right: -8px !important;
        bottom: unset !important;
        top: 50% !important;
        width: unset !important;
    }

    .navigation-dots-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        position: relative;
    }

    .nav-dots {
        border-radius: 46px;
        background: #D9D9D9;
        width: 10px;
        height: 10px;
        cursor: pointer;
    }


    .nav-dots-active {
        width: 10px;
        height: 27px;
        border-radius: 46px;
        background: #333;
        position: absolute;
        transition: all 0.4s ease-in;
        cursor: pointer;
    }

    .nav-dot-top {
        top: 0;
    }

    .nav-dot-middle {
        top: 25%
    }

    .nav-dot-center {
        top: 55%
    }

    .nav-dot-bottom {
        top: calc(100% - 27px);
    }

    @media (min-width:767.98px) {
        .contents-container {
            width: 500px;
        }

        .progress-wrapper ol {
            padding-left: 24px;
        }

        .progress-stepper-label {
            margin: 4px 26px 0px 0px;
        }

        .progress-title {
            margin-bottom: 64px;
        }

        .slick-dots {
            right: 8px !important;
        }
    }

    @media (min-width:991.98px) {
        .slick-list {
            max-height: 100vh;
        }

        .progress-wrapper-box {
            height: 100vh;
        }

        .contents-container {
            margin-right: 0;
        }
    }


    @media (min-width:1199.98px) {
        .contents-container {
            width: 700px;
        }

        .slick-dots {
            right: 40px !important;
        }
    }
}