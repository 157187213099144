.domain-section-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.domain-cards-wrapper-box {
    // background-image: url('/assets/DomainProgress/domain-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: background-image 0.8s ease-in-out;
}

// style={{backgroundImage: `url(${backgroundimage})`}}
.domain-cards-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    min-height: 60vh;
    width: 100%;
}


.domain-cards-container {
    border-radius: 24px;
    background: rgba(19, 19, 19, 0.45);
    backdrop-filter: blur(10px);
    padding: 20px;
}

.domain-description {
    color: rgba(243, 243, 243, 0.60);
    margin-bottom: 14px;
    margin-right: 60px;
}

.domain-link-arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    cursor: auto;
}

.domain-link-arrow p {
    color: #F3F3F3;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
}

.domain-arrows-container {
    width: 16px;
    height: auto;
    overflow: hidden;
}

.domain-arrows {
    cursor: pointer;
    overflow: hidden;
    width: 12px;
    height: 12px;
    object-fit: contain;
}

.domain-input-field-box {
    background-image:
        linear-gradient(to right, #EDEDED 1.5px, transparent 1.5px),
        linear-gradient(to bottom, #EDEDED 1.5px, transparent 1.5px);
    background-size: 77px 69px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    min-height: 700px
}

.domain-input-field-box::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
}

@media (min-width: 767.98px) {
    .domain-cards-wrapper {
        height: 100%;
        padding: 72px;
    }

    .domain-cards-container {
        padding: 32px;
    }

}

@media (min-width:991.98px) {
    .domain-section-wrapper {
        flex-wrap: nowrap;
        flex-direction: row-reverse;
    }

    .domain-cards-wrapper,
    .domain-input-field-box {
        height: 100vh;
    }

    .domain-cards-container {
        max-width: 700px;
        padding: 25px;
    }

    .domain-cards-wrapper-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .domain-description {
        margin-right: 20px;
    }
}