@import '../../StyleConstants.scss';

.sidebar-wrapper {
    padding: 20px 16px;
}

.mei-logo-small {
    margin-bottom: 36px;
}


.main-nav-logos-container {
    gap: 24px;
}

.nav-logo {
    width: 22px;
    height: 22px;
}

.nav-logo-container {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}

.mei-dark {
    .sidebar-wrapper {
        border-right: 2px solid $very-mild-grey;
    }
}

.btn-close {
    padding: 6px !important;
    cursor: pointer;
    background: url('/assets/ChatHistory/crossLight.svg') no-repeat center center;
    width: 44px;
    height: 44px;
    background-size: 14px 14px;
    border: none;
    border-radius: 50%;
    background-color: #343639;
    margin: 0 !important;
}

.secret-key-wrapper .modal-header {
    border-bottom: 1px solid $light-grey;
    justify-content: space-between !important;
    font-weight: 400;
    padding: 12px;
}

.secret-key-wrapper .modal-body {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
}

.faq-wrapper .modal-header {
    border-bottom: 1px solid $light-grey;
    justify-content: flex-end !important;
}

.faq-wrapper {
    margin: 70px 12px 12px 12px;
    border-radius: 36px;
    border-bottom: 1px solid $light-grey;
}

.faq-wrapper,
.modal-fullscreen,
.faq-wrapper .modal-content {
    width: calc(100vw - 24px);
    height: calc(100vh - 82px);
}

.api-secret-logo {
    width: 24px;
    height: auto;
    margin-right: 12px;
}

.reload-icon {
    width: 18px;
    height: auto;
    margin-left: 12px;
    cursor: pointer;
}

.download-key {
    padding: 12px;
    border: none;
    cursor: pointer;
    border-radius: 12px;
    margin-bottom: 12px;
    font-weight: 500;
    cursor: pointer;
}

.secret-key-download-container {
    margin-left: 36px;
}

.secret-key {
    font-weight: 300;
    word-wrap: break-word;
    position: relative;
    width: 100%;
    max-height: 200px;
    overflow: hidden;
}

.secret-key::after {
    position: absolute;
    content: "";
    background: linear-gradient(0deg, #252628 -14.4%, rgba(37, 38, 40, 0.7) 42.77%, rgba(37, 38, 40, 0.06) 105.96%);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%;
}

.faq-contents-container {
    max-width: 810px;
    margin-bottom: 20px;
    height: max-content;
}

.faq-description {
    font-weight: 500;
}

.faq-question-answers-wrapper {
    margin-bottom: 32px;
}

.faq-question-answers {
    margin-top: 48px;
    border-radius: 8px;

    tr:first-child td:first-child {
        border-top-left-radius: 8px;
    }

    tr:first-child td:nth-child(2) {
        border-top-right-radius: 8px;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
    }

    tr:last-child td:nth-child(2) {
        border-bottom-right-radius: 8px;
    }

    tr td {
        font-weight: 500;
        width: 50%;
        padding: 16px;
    }

    &,
    td {
        border: 1px solid #666666;
        border-collapse: collapse;
    }
}

@media only screen and (min-width:1199.98px) {
    .faq-wrapper {
        margin: 12px 12px 12px 70px;
        border-radius: 36px;
    }

    .faq-wrapper {
        width: calc(100vw - 84px) !important;
        height: calc(100vh - 24px) !important;
    }

    .modal-fullscreen,
    .faq-wrapper .modal-content {
        width: calc(100vw - 84px) !important;
        height: calc(100vh - 26px) !important;
    }

    .faq-title,
    .faq-description {
        width: 80%;
    }


    // .modal-backdrop {
    //     display: none;
    // }
}

@media only screen and (min-width:575.98px) {
    .secret-key-wrapper .modal-header {
        font-weight: 400;
        padding: 40px;
    }

    .secret-key-wrapper .modal-body {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
    }

}